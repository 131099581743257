import React from 'react'

const NotFoundPage = () => (
  <div>
    return <iframe id="fullFrame" src="https://postcards.maxbittker.com/write/"></iframe>
  
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
  </div>
)

export default NotFoundPage
